import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import "./App.css";

import { px } from './styles/util';

import {
  Progress,
} from 'antd';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: ${px(400)};
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  color: #222;
  background-color: #fdfcdc;
  font-family: 'Comfortaa';

  & h1 {
    font-size: ${px(24)};
    text-align: center;
    margin: ${px(32)};

    & img {
      height: ${px(32)};
      margin-bottom: ${px(16)};
    }
  }

  & p {
    font-size: ${px(12)};
    text-align: center;
    margin: ${px(32)};
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
`;

const StyledProgress = styled(Progress)`
  position: absolute;
`;

const ProgressContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & h2 {
    font-size: ${px(64)};
    margin: 0;
    padding: 0;
  }

  & h3 {
    font-size: ${px(14)};
    margin: 0;
    padding: 0;
  }
`;

const App = () => {
  const size = useWindowSize();
  const [holders, setHolders] = useState();

  useEffect(() => {
     axios.get('https://dust-holders.scoopshop.io/', {
       headers: {
         'Accept': 'text/plain',
       }
     })
      .then((res) => {
        console.log(res.data);
        setHolders(res.data);
      });
  }, []);

  return (
    <Container>
      <h1>Wallets with at least <b>375</b> <img src={process.env.PUBLIC_URL + '/dustlabs.png'} /></h1>
      <ProgressContainer>
        <StyledProgress
          percent={holders ? Math.round((holders / 15000) * 100) : 0}
          type="circle"
          width={Math.min(size.width, size.height) / 2.0}
          trailColor="#fed9b7"
          strokeColor="#f07167"
          showInfo={false}
        />
        <ProgressContent>
          <h2>{holders ? `${parseInt(holders).toLocaleString()}` : false}</h2>
          <h3>{holders ? `${Math.round((holders / 15000) * 100)}% of ${(15000).toLocaleString()}` : "Loading..."}</h3>
        </ProgressContent>
      </ProgressContainer>
      <p>With ♥ for <a href="https://www.y00ts.com/" target="_blank" rel="noopener noreferrer">y00ts</a> by <a href="https://twitter.com/penneydude" target="_blank" rel="noopener noreferrer">@penneydude</a></p>
    </Container>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default App;
